<template lang="pug">
#home 
    van-search(
        left-icon="mysearch",
        @focus="noBomBox",
        placeholder="请输入搜索关键词",
        @click="search"
    )
    ul.navbox-li
        li(:class="{ active: active == 0 }", @click="chooseTab(0)") 新车
        li(:class="{ active: active == 1 }", @click="chooseTab(1)") 二手车
    .content(v-if="active == 0")
        van-list.xin-box(
            v-model="loading",
            :finished="finished",
            @load="onLoad"
        )
            template(#default)
                .xin-li(@click="detail(item.id)", v-for="item in list")
                    .imgbox(
                        :style="{ backgroundImage: 'url(' + item.thum_image_url + ')' }"
                    )
                        //- img(:src="item.thum_image_url")
                        //- .posi-li
                            span 立省
                            span {{ item.image_tag }}
                    .namebox 
                        span.test-img(v-if="item.is_check_car == 1")
                            img(src="../../assets/mine/test.png")
                        span.name {{ item.title }}
                        span.xinup(v-if="item.title_tag !=''") {{ item.title_tag }}
                    .numbox
                        .numbox-l
                            span.num-1 指导价：¥
                            span.num-2 {{ item.guide_price }}
                            //- span.num-3 .00
                        //- .numbox-r
                        //-     span.num-1 {{ item.guide_price }}
    .content(v-if="active == 1")
        van-list.xin-box(
            v-model="loading",
            :finished="finished",
            @load="onLoad"
        )
            template(#default)
                .xin-li(@click="detail(item.id)", v-for="item in list2")
                    .imgbox(
                        :style="{ backgroundImage: 'url(' + item.thum_image_url + ')' }"
                    )
                        //- img(:src="item.thum_image_url")
                        //- .posi-li
                            span 立省
                            span {{ item.image_tag }}
                    .namebox
                        span.test-img(v-if="item.is_check_car == 1")
                            img(src="../../assets/mine/test.png")
                        span.name {{ item.title }}
                        span.xinup(v-if="item.title_tag !=''") {{ item.title_tag }}
                    .numbox
                        .numbox-l
                            span.num-1 指导价：¥
                            span.num-2 {{ item.guide_price }}
                            //- span.num-3 .00
                        //- .numbox-r
                        //-     span.num-1 {{ item.guide_price }}

</template>
<style src="../../stylus/reset.styl" lang="stylus"></style>
<style src="./index.styl" lang="stylus"></style>
 



<script>
import { httpIndex, weixinLogin, info, getWxloginCode } from "../../config/request";
import ShareImpl from '@/weixinApi.js';

import {
  getQueryVariable
} from '../../util/helper'

export default {
    name: "home",
    data() {
        return {
            active: 0,
            list: [],
            list2: [],
            loading: false,
            finished: false,
            page: 0,
            value: "",

            head_img_url: "",
            username: "",
            app_id: "",
            code:"",
            active: '0'
        };
    },

    async created() {
        // this.onLoad();
    },

    methods: {
        noBomBox(Event) {
            document.activeElement.blur();
        },
        search() {
            this.$router.push({
                path: "/search?from=home_t" + new Date().getTime(),
            });
        },
        onLoad() {
            this.page++;
            // 异步更新数据
            this.getData();
        },

        getData(val) {
            let cate = (location.href.split('?')[1] && location.href.split('?')[1].length != 0) ? (location.href.split('?')[1].split('&')[0] && location.href.split('?')[1].split('&')[0].length != 0  ? location.href.split('?')[1].split('&')[0].split('=')[1] : '0') : '0'
            // let cate = location.href.split('?')[1].split('=')[1]
            this.active = cate
            console.log(cate,'cate233')
            if (val == 0 || val == 1) {
                cate = val 
                this.active = val
            }
            console.log(cate,'cate++')
            httpIndex({ page: this.page,cate: cate }).then((res) => {
                if (res.code === 20000) {
                  if (cate == 0) {
                    let list = res.data;
                    this.fun.setMoreData(this, list, "list");
                  } else {
                    let list2 = res.data;
                    this.fun.setMoreData(this, list2, "list2");
                  }
                    // this.list = this.list.concat(res.data);
                    // if (!res.data.length) this.finished = true;
                    // this.loading = false;
                    let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res,11111);
                    let img = window.location.protocol + '//' + window.location.hostname + '/' + 'd.jpg';
                    let shareTitle = '链上汽车';
                    let shareDesc = res.data.share ? res.data.share.share_title : '链上好车';
                    console.log(shareDesc,'分享title')
                    let shareImg =  img;
                    // let shareImg = img;
                    console.log(shareImg,'图片111')
                    console.log(img,'本地111')
                    ShareImpl({
                      shareTitle,
                      shareDesc,
                      shareUrl,
                      shareImg
                    });
                }
            });
        },

        detail(val) {
            this.$router.push({ path: "/detail?id=" + val });
        },

        chooseTab(val) {
            // this.active = val;
            console.log(this.active,'active')
            this.page = 1;
            // this.list = [];
            this.getData(val);
        },



        /*chooseTab(val) {
            this.page = 1;
            this.getData();
        },

        init() {
            this.getData2();
        },
        getData2() {
            info().then((res) => {
                this.app_id = res.data;
                if (res.code == 50001) {
                    this.tologin();
                } else {
                    this.head_img_url = res.data.info.head_img_url;
                    this.username = res.data.info.username;
                }
            });
        },

        tologin() {
            if (window.location.href.split("code=").length > 1) {
                this.code = window.location.href
                    .split("code=")[1]
                    .split("&state=")[0];
            } else {
                this.code = "";
            }
            if (this.code == "") {
                this.wxlogin();
            } else {
                this.login();
            }
        },

        wxlogin() {
            //const appId = "wx3fea410e2c24d247"; // 测试网
            //const appId = "wxd63cd34baea12b3f"; // 外网
            const appId = this.app_id;
            const thisUrl = encodeURIComponent(window.location.href);
            const SCOPE = "snsapi_userinfo";
            const dates = new Date();
            const STATE = dates.getTime();
            const wxurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${thisUrl}&response_type=code&scope=${SCOPE}&state=${STATE}#wechat_redirect`;
            window.location.href = wxurl;
        },

        login() {
            weixinLogin({ code: this.code }).then((res) => {
                console.log(res.code,"code");
                if (res.code == 20000) {
                    console.log("------登入----");
                    this.getData();
                }else{
                    console.log("------res----");
                    return false;
                }
                
            });
        },*/
    },
};
</script>
 