import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'


import {
  getQueryVariable
} from '../util/helper'

import {
  get,
  post
} from '../config/http'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/User/index.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About/index.vue')
    },
    {
        path: '/personnal',
        name: 'personnal',
        component: () => import('../views/personnal/index.vue')
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/index.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact/index.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/detail/index.vue')
    },
    {
        path: '/fenqi',
        name: 'fenqi',
        component: () => import('../views/fenqi/index.vue')
    },
    {
        path: '/opinion',
        name: 'opinion',
        component: () => import('../views/opinion/index.vue')
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/search/index.vue')
    },
]

const router = new VueRouter({
    routes
})




//授权登录

router.beforeEach((to, from, next) => {
    // 开发时打开
    // next()
    // return

  if (process.env.VUE_APP_AUTH == 1) {
    next()
    // return
  }

  localStorage.setItem('routeHash', window.location.hash) // 记录当前路由hash
  const isOauth = localStorage.getItem('isOauth')
  const code = getQueryVariable('code')
  const callback = window.location.href

  console.log(isOauth,"isOauth")
  console.log(code,"code")
  console.log(callback,"callback")

  if (code === false && isOauth === null) {
    get('/mobile/login/get_one', {
      callback: callback
    }).then(res => {
      console.log(res,'res')
      console.log('获取微信授权url成功')
      window.location.href = res.data
      next()
    }).catch(error => {
      next()
    })
  }

  console.log(code,"code_new")
  console.log(isOauth,"isOauth_new")

  if (code !== false && isOauth === null) {
    post('/mobile/login/post_one', {
      code: code
    }).then(res => {
      console.log('登录成功')
      localStorage.setItem('isOauth', 1)
      const routeHash = localStorage.getItem('routeHash'); // 读取路由hash

      console.log(routeHash,"routeHash")
      
      let url = window.location.href

      console.log(url,"url_2")
      //url = url.substring(0, url.indexOf('&code')); // 裁剪多余参数
      url = url.substring(0, url.indexOf('?code')); // 裁剪多余参数


      console.log(url,"url_3")

      console.log(url + routeHash,'href_') 

      window.location.href = url + routeHash
      next()
    }).catch(error => {
      next()
    })
  }
  next()
})




export default router